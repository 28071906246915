<template>
  <div class="flex flex-col sm:flex-row gap-y-4 w-full h-full">
    <!-- img -->
    <section class="w-48 h-48">
      <content-viewer :filePath="ds.target_thumbnail" />
    </section>

    <!-- description -->
    <section class="ml-0 sm:ml-5 my-auto tracking-wide leading-relaxed">
      <h1 class="text-lg font-semibold mb-2">
        {{ ds.target_name }}
      </h1>

      <p class="text-md" style="color: #8a8a8a">
        {{ ds.event }} by <b>{{ ds.user_nickname }}</b>
      </p>
      <p class="mt-1 mb-2 text-xs font-medium" style="color: #8a8a8a">
        {{ timeLeft(ds.created_at) }}
      </p>
    </section>
  </div>
</template>

<script>
import ContentViewer from "@/components/ContentViewer.vue";

export default {
  props: {
    ds: Object,
  },

  components: {
    ContentViewer,
  },

  methods: {
    remainTime(endtime) {
      const total = Date.parse(new Date()) - Date.parse(endtime);
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },

    timeLeft(_dt) {
      const tm = this.remainTime(_dt);
      let res = "";
      if (Number(tm.days) > 0) {
        if (Number(tm.days) == 1) {
          res = `${tm.days} day`;
        } else {
          res = `${tm.days} days`;
        }
      }
      if (Number(tm.hours) > 0) {
        res = `${res} ${tm.hours} hours`;
        if (Number(tm.hours) >= 1) {
          return res + " ago";
        }
      }
      if (Number(tm.minutes) > 0) {
        res = `${res} ${tm.minutes} minutes`;
      }
      res = `${res} ${tm.seconds} seconds ago`;

      return res;
    },
  },
};
</script>
