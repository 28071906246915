<template>
  <div class="w-full lg:w-4/5 overflow-initial float-left">
    <div
      id="aaa"
      class="relative market-album my-28 mx-auto cursor-pointer relative"
      v-for="item in items"
      :key="item.id"
      data-aos="fade-right"
      data-aos-offset="0"
      data-aos-easing="ease-in-sine"
      @click="onProductDetail(item.target_type, item.target_id)"
    >
      <activity-purchased-item v-if="item.event == 'purchased'" :ds="item" />
      <activity-bid-item v-else-if="item.event == 'bid'" :ds="item" />
      <activity-listed-item v-else-if="item.event == 'listed'" :ds="item" />
      <activity-minted-item v-else-if="item.event == 'mint'" :ds="item" />
      <activity-winning-item
        v-else-if="item.event == 'wontheauction'"
        :ds="item"
      />
    </div>
  </div>
</template>

<script>
import ActivityPurchasedItem from "./ActivityPurchasedItem.vue";
import ActivityBidItem from "./ActivityBidItem.vue";
import ActivityListedItem from "./ActivityListedItem.vue";
import ActivityMintedItem from "./ActivityMintedItem.vue";
import ActivityWinningItem from "./ActivityWinningItem.vue";

export default {
  props: {
    items: Array,
  },

  components: {
    ActivityPurchasedItem,
    ActivityBidItem,
    ActivityListedItem,
    ActivityMintedItem,
    ActivityWinningItem,
  },

  methods: {
    onProductDetail: function (type, id) {
      if (type === 2) {
        this.$router.push(`/product/${id}`);
      } else if (type === 5) {
        this.$router.push(`/market/order/${id}`);
      }
    },
  },
};
</script>

<style>
.item-img {
  background-image: url("../../../assets/img/basic_image.png");
  background-position: center;
  background-size: cover;
  transform: scale(1.1);
}
.title_underline:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1.5px;
  left: 50%;
  position: absolute;
  background: #444444;
  transition: width 0.6s ease 0s, left 0.6s ease 0s;
  width: 0;
}
.title_underline:hover:after {
  width: 90%;
  left: 0;
}
.my-wrapper {
  /* width:100%;
    height:100%;
    margin: 0 auto;
    position:relative; */
}
.market-album {
  /* width:80%;
    height:100%;
    position: relative; */
  display: -webkit-box;
  /* margin:0 auto;
    margin-top:5%; */
}

.market-album {
  transition: all 0.2s ease-in-out;
}
.market-album:hover {
  transform: scale(1.1);
  padding-left: 0%;
  /* cursor: pointer; */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.act-point svg {
  display: initial;
  margin-bottom: 1px;
}
#aaa:nth-child(even) {
  /* width:100%; */
  padding-left: 25%;
}

.hover-bg-gray > p:hover {
  background: #262626;
}
@media screen and (max-width: 900px) {
  #aaa:nth-child(even) {
    margin-left: 15%;
    padding: 0;
  }
  #aaa:nth-child(odd) {
    margin-left: 1%;
  }
}
</style>
