<template>
  <div class="flex flex-wrap">
    <section class="w-full mx-auto relative">
      <div class="w-full mx-auto">
        <div v-if="loading">
          <parula-loading />
        </div>
        <div v-else class="w-11/12 mx-auto w-full mx-3 relative">
          <div class="w-full lg:w-1/4 block lg:absolute lg:right-0 text-left">
            <ActivityFilter
              :items="itemList"
              @changeLoading="changeLoading"
              @changeFilter="changeData"
            />
          </div>
          <div class="md:w-4/5 overflow-initial mx-auto float-left">
            <ActivityList :items="itemList" />
          </div>
        </div>
      </div>
    </section>

    <section class="flex justify-center w-full mt-12">
      <button
        v-if="itemList.length !== 0"
        class="
          py-2
          px-4
          bg-white
          border border-black
          hover:bg-black hover:text-white
          text-black text-xs
          focus:outline-none focus:shadow-outline
          md:text-base
        "
        type="button"
        @click="onSeeMore"
      >
        View More
      </button>
    </section>
  </div>
</template>

<script>
import OpenapiService from "../../services/openapi.service";
import ActivityFilter from "./sub/ActivityFilter.vue";
import ActivityList from "./sub/ActivityList.vue";
import ParulaLoading from "@/components/ParulaLoading.vue";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data: function () {
    return {
      loading: false,
      itemList: [],
      initPageSize: 10,
      pageSize: 10,
      filterBy: "all",
    };
  },

  components: {
    ActivityFilter,
    ActivityList,
    ParulaLoading,
  },

  // computed: {
  //   itemListTest: {
  //     get() {
  //       // console.log("this.itemList", this.itemList);

  //       return this.itemList;
  //     },
  //     set(val) {
  //       console.log("val ::::", val);
  //     },
  //   },
  // },

  created: function () {
    this.loading = true;
    this.getHistoryList(1, this.initPageSize, this.filterBy);
    AOS.init();
  },

  methods: {
    getHistoryList(page, pageSize, filterBy) {
      this.loading = true;

      OpenapiService.getHistoryList(page, pageSize, filterBy).then((res) => {
        this.itemList = res.data.items;
        this.loading = false;
      });
    },

    onSeeMore() {
      this.pageSize = this.pageSize + this.initPageSize;
      this.getHistoryList(1, this.pageSize, this.filterBy);
    },

    changeData: function (filterBy) {
      this.filterBy = filterBy;
      this.pageSize = this.initPageSize;
      this.getHistoryList(1, this.initPageSize, this.filterBy);
    },

    changeLoading: function () {
      this.loading = !this.loading;
    },
  },
};
</script>

<style scoped>
#aaa:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1.5px;
  left: 50%;
  position: absolute;
  background: #000;
  transition: width 1s ease 0s, left 1s ease 0s;
  width: 0;
}
#aaa:hover:after {
  width: 90%;
  left: 0;
}

.market-album {
  display: -webkit-box;
}

.market-album {
  transition: all 0.2s ease-in-out;
}
.market-album:hover {
  transform: scale(1.1);
  padding-left: 0%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.act-point svg {
  display: initial;
  margin-bottom: 1px;
}
#aaa:nth-child(even) {
  width: 100%;
  margin-left: 25%;
}

.hover-bg-gray > p:hover {
  background: #262626;
}
</style>
