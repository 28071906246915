<template>
  <div class="w-full relative">
    <div class="lg:fixed mt-8 lg:mt-12 lg:pl-20 bg-transparent z-10">
      <h1 class="font-bold text-lg mx-2 lg:mx-0 mb-1">Filters</h1>
      <div
        class="
          flex
          lg:flex-col
          max-w-full
          mt-2
          grid grid-cols-4
          lg:grid-cols-1 lg:w-60
          text-sm
          over
          overscroll-x-auto
        "
      >
        <button
          class="
            text-center
            mr-2
            px-2
            py-2
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:text-white
            button__hover
            lg:w-20
          "
          @click="all"
          :class="{
            white: clickedBtn == false,
            clicked: clickedBtn == true,
          }"
        >
          All
        </button>
        <button
          class="
            text-center
            mr-2
            px-2
            py-2
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:text-white
            button__hover
            lg:w-24 lg:mt-2
          "
          @click="mint"
          :class="{
            white: clickedBtn2 == false,
            clicked: clickedBtn2 == true,
          }"
        >
          Mint
        </button>
        <button
          class="
            text-center
            mr-2
            px-2
            py-2
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:text-white
            button__hover
            lg:w-24 lg:mt-2
          "
          @click="sold"
          :class="{
            white: clickedBtn3 == false,
            clicked: clickedBtn3 == true,
          }"
        >
          Sold
        </button>
        <button
          class="
            text-center
            px-2
            py-2
            text-black
            transition-colors
            duration-150
            border border-black
            focus:shadow-outline
            hover:text-white
            button__hover
            lg:w-24 lg:mt-2
          "
          @click="bid"
          :class="{
            white: clickedBtn4 == false,
            clicked: clickedBtn4 == true,
          }"
        >
          Bids
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: {
    changeFilter: Array,
  },

  data: function () {
    return {
      clickedBtn: false,
      clickedBtn2: false,
      clickedBtn3: false,
      clickedBtn4: false,
    };
  },

  methods: {
    all: function () {
      window.scrollTo(0, 0);

      this.clickedBtn = !this.clickedBtn;
      this.clickedBtn2 = false;
      this.clickedBtn3 = false;
      this.clickedBtn4 = false;

      this.$emit("changeFilter", "all");
    },

    mint: function () {
      window.scrollTo(0, 0);

      this.clickedBtn2 = !this.clickedBtn2;
      this.clickedBtn = false;
      this.clickedBtn3 = false;
      this.clickedBtn4 = false;

      this.$emit("changeFilter", "mint");
    },

    sold: function () {
      window.scrollTo(0, 0);

      this.clickedBtn3 = !this.clickedBtn3;
      this.clickedBtn = false;
      this.clickedBtn2 = false;
      this.clickedBtn4 = false;

      this.$emit("changeFilter", "sold");
    },

    bid: function () {
      window.scrollTo(0, 0);

      this.clickedBtn4 = !this.clickedBtn4;
      this.clickedBtn = false;
      this.clickedBtn2 = false;
      this.clickedBtn3 = false;

      this.$emit("changeFilter", "bid");
    },
  },
};
</script>

<style>
.button__hover:hover {
  background: #262626;
}

.white {
  background-color: white;
}

.clicked {
  background-color: #262626;
  color: white !important;
}
</style>
